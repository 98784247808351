import React, { useState } from "react";
import {
  Container, Box, Typography, Button, TextField, Select, MenuItem,
  FormControl, InputLabel, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Backdrop, CircularProgress
} from "@mui/material";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import merah from "../../imgAVIF/merah.avif";
import kuning from "../../imgAVIF/kuning.avif";
import hijau from "../../imgAVIF/Hijau.avif";

const flagIcons = { merah, kuning, hijau };

const DataHistoryFlagging = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [downloadFormat, setDownloadFormat] = useState("pdf");
  const [selectedStation, setSelectedStation] = useState("All");
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchDataFromAPI = async () => {
    const stations = selectedStation === "All" ? ["GKU", "TULT", "Deli"] : [selectedStation];
    setOpen(true);

    try {
      const updatedDataPromises = stations.map(async (station) => {
        const response = await fetch(
          `https://biru-langit.com/api/Flagging/${station}?time_low=${startDate.toISOString()}&time_high=${endDate.toISOString()}`
        );
        const { data } = await response.json();

        return data.map((item, index) => ({
          id: `${station}-${index + 1}`,
          station,
          lastActive: item["Start Check"] || "N/A",
          lastOff: item["End Check"] || "N/A",
          flagNotif: item["Flag Notif"],
          flagIcon:
            item["Flag Notif"] === 0 ? flagIcons.hijau :
            item["Flag Notif"] === 1 ? flagIcons.kuning : flagIcons.merah,
          keterangan: item["Keterangan"] || "No description available",
          parameter: station,
        }));
      });

      const allData = (await Promise.all(updatedDataPromises)).flat();
      const sortedData = allData.sort((a, b) => new Date(a.lastActive) - new Date(b.lastActive));
      const updatedDataWithNo = sortedData.map((item, index) => ({
        ...item,
        no: index + 1,
      }));

      setFilteredData(updatedDataWithNo);
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData([]);
    }
    setOpen(false);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#data-table" });
    doc.save("data-history-flagging.pdf");
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data History Flagging");
    XLSX.writeFile(workbook, "data-history-flagging.xlsx");
  };

  return (
    <Container>
      <Box mb={3}>
        <Typography variant="h1" fontWeight="semi-bold">Data History Flagging</Typography>
        <Typography variant="subtitle1">Data History of Flagging System</Typography>
      </Box>

      <Box mb={2} display="flex" gap="10px">
        <TextField
          label="Start Date" type="date"
          value={startDate.toISOString().split("T")[0]}
          onChange={(e) => setStartDate(new Date(e.target.value))}
          InputLabelProps={{ shrink: true }} sx={{ width: "20%" }}
        />
        <TextField
          label="End Date" type="date"
          value={endDate.toISOString().split("T")[0]}
          onChange={(e) => setEndDate(new Date(e.target.value))}
          InputLabelProps={{ shrink: true }} sx={{ width: "20%" }}
        />

        <FormControl sx={{ width: "20%" }}>
          <InputLabel>Station</InputLabel>
          <Select
            value={selectedStation}
            onChange={(e) => setSelectedStation(e.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="TULT">TULT</MenuItem>
            <MenuItem value="GKU">GKU</MenuItem>
            <MenuItem value="Deli">Deli</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ width: "20%" }}>
          <InputLabel>Download Format</InputLabel>
          <Select
            value={downloadFormat}
            onChange={(e) => setDownloadFormat(e.target.value)}
          >
            <MenuItem value="pdf">PDF</MenuItem>
            <MenuItem value="excel">Excel</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box p="10px" bgcolor="#D6E6F2" overflow="auto">
        <Typography variant="subtitle2" mb={1}>Total entries: {filteredData.length}</Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table id="data-table">
            <TableHead>
              <TableRow>
                {["No", "Station", "Last Active", "Last Off", "Flag Icon", "Keterangan", "Parameters"].map((header) => (
                  <TableCell key={header} sx={{ fontWeight: "bold", textAlign: "center", padding: "10px" }}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.no}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.station}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.lastActive}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.lastOff}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>
                    <img src={row.flagIcon} alt={`Flag ${row.flagNotif}`} width="30" height="30" />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.keterangan}</TableCell>
                  <TableCell sx={{ textAlign: "center", padding: "10px" }}>{row.parameter}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mt={2} display="flex" gap="10px">
        <Button variant="contained" onClick={fetchDataFromAPI}>Apply Filter</Button>
        <Button
          variant="outlined"
          onClick={() => downloadFormat === "pdf" ? downloadPDF() : downloadExcel()}
        >
          Download {downloadFormat.toUpperCase()}
        </Button>
      </Box>

      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default DataHistoryFlagging;
