import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import merah from "../../imgAVIF/merah.avif";
import hijau from "../../imgAVIF/Hijau.avif";
import kuning from "../../imgAVIF/kuning.avif";
import NotificationPanel from "../../Components/Dashboard/ComponentDashboard/NotificationPanel";
import { fetchFlaggingData } from "../../Components/Dashboard/ComponentDashboard/fetchNotifications";

const FlaggingSystem = ({ onNewNotifications }) => {
  const theme = useTheme();
  const [station, setStation] = useState("All Station");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [notifications, setNotifications] = useState([]);

  // Fungsi untuk menambahkan nomor urut pada data
  const reindexRows = (data) => {
    return data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));
  };

  // Handle perubahan station dan filter data
  const handleStationChange = (event) => {
    const selectedStation = event.target.value;
    setStation(selectedStation);

    if (selectedStation === "All Station") {
      const sortedRows = reindexRows([...rows].sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive)));
      setFilteredRows(sortedRows);
    } else {
      const filtered = rows.filter((row) => row.station === selectedStation);
      setFilteredRows(reindexRows(filtered.sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive))));
    }
  };

  // Kolom DataGrid
  const columns = [
    { field: "no", headerName: "No", flex: 0.5, minWidth: 90, headerAlign: "center", align: "center" },
    {
      field: "station",
      headerName: "Station",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: () => (
        <Select value={station} onChange={handleStationChange} displayEmpty sx={{ width: "100%", textAlign: "center" }}>
          <MenuItem value="All Station">All</MenuItem>
          <MenuItem value="TULT">TULT</MenuItem>
          <MenuItem value="GKU">GKU</MenuItem>
          <MenuItem value="Deli">Deli</MenuItem>
        </Select>
      ),
    },
    { field: "lastActive", headerName: "Start Check", flex: 1, minWidth: 180, headerAlign: "center", align: "center" },
    { field: "lastOff", headerName: "End Check", flex: 1, minWidth: 180, headerAlign: "center", align: "center" },
    {
      field: "flagNotif",
      headerName: "Flag Notif",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <img src={params.row.flagIcon} alt="Flag" width="30" height="30" />,
    },
    {
      field: "keterangan",
      headerName: "Keterangan",
      flex: 2,
      minWidth: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography variant="body2" noWrap={false} sx={{ whiteSpace: "normal", textAlign: "center" }}>
          {params.value}
        </Typography>
      ),
    },
    { field: "parameter", headerName: "Parameters", flex: 1, minWidth: 150, headerAlign: "center", align: "center" },
  ];

  // Ambil data dari API dan reindex nomor
  useEffect(() => {
    fetchFlaggingData(
      (loadedRows) => {
        const indexedRows = reindexRows([...loadedRows].sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive)));
        setRows(indexedRows);
        setFilteredRows(indexedRows);
      },
      setNotifications,
      onNewNotifications,
      {
        merah,
        kuning,
        hijau,
      }
    );
  }, [onNewNotifications]);

  return (
    <Box m="20px">
      {/* <NotificationPanel notifications={notifications} /> */}
      <Typography variant="h1" gutterBottom fontWeight="semi-bold" align="left">
        Flagging System
      </Typography>
      <Box width="100%" sx={{ overflowX: "auto" }}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          autoHeight
          pagination
          pageSizeOptions={[15, 30, 50]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}
          sx={{
            "& .MuiDataGrid-root": { width: "100%" },
            "& .MuiDataGrid-columnHeader": { backgroundColor: "#D6E6F2", fontWeight: "bold" },
            "& .MuiDataGrid-cell": { backgroundColor: "#f9f9f9", textAlign: "center" },
            "& .MuiDataGrid-footerContainer": { backgroundColor: "#D6E6F2" },
          }}
        />
      </Box>
    </Box>
  );
};

export default FlaggingSystem;
