import React, { useEffect, useState, useMemo } from "react";
import "chartjs-adapter-date-fns";
import ParameterButton from "../../../Components/Chart/ChartComponent/ParameterButton";
import ChartComponent from "../../../Components/Chart/ChartComponent/chartComponent";
import IndicatorGrid from "../../../Components/Chart/ChartComponent/IndicatorGrid";
import { dataAQMSCisanti } from "./DataAQMSCisanti";

function ChartAQMSCisanti({ res }) {
    const AQMSCisanti = useMemo(() => dataAQMSCisanti(), []);
    const initialButtonData = useMemo(() => {
        const buttonData = {};
        AQMSCisanti.forEach((item) => {
            buttonData[item.parameter] = [];
        });
        return buttonData;
    }, [AQMSCisanti]);

    const [selectedParameter, setSelectedParameter] = useState("pm25");
    const [selectedParameterUnit, setSelectedParameterUnit] = useState("µg/m³");
    const [selectedParameterName, setSelectedParameterName] = useState("PM2.5");
    const [standard, setStandard] = useState({
        high: 50,
        low: 10,
    });
    const [buttonData, setButtonData] = useState(initialButtonData);
    const [data, setData] = useState({
        labels: [],
        datasets: [
            {
                label: "",
                data: [],
            },
        ],
    });
    const [isDataAvailable, setIsDataAvailable] = useState(false);

    const handleDataChange = (parameter, unit, high, low, name) => {
        setSelectedParameter(parameter);
        setSelectedParameterUnit(unit);
        setStandard({ high, low });
        setSelectedParameterName(name);
        const newDataAvailable = !!res && res.length > 0;
        setIsDataAvailable(newDataAvailable);
    };

    useEffect(() => {
        if (res && res.length > 0) {
            const oneHourAgo = new Date(Date.now() - 2 * 60 * 60 * 1000);

            const filteredRes = res.filter((dataObj) => new Date(dataObj.created_at) >= oneHourAgo);

            const labels = filteredRes.map((dataObj) => dataObj.created_at);
            const dataPoints = filteredRes.map((dataObj) => {
                const value = parseFloat(dataObj[selectedParameter]);
                return isNaN(value) ? null : value.toFixed(1);
            });

            setData({
                labels,
                datasets: [
                    {
                        label: `${selectedParameter.toUpperCase()} (${selectedParameterUnit})`,
                        data: dataPoints,
                        backgroundColor: "#163461",
                    },
                ],
            });

            setButtonData({
                temperature: filteredRes.slice(-1).map((dataObj) => parseFloat(dataObj.temperature).toFixed(1)),
                humidity: filteredRes.slice(-1).map((dataObj) => parseFloat(dataObj.humidity).toFixed(1)),
                pm25: filteredRes.slice(-1).map((dataObj) => parseFloat(dataObj.pm25).toFixed(1)),
                co2: filteredRes.slice(-1).map((dataObj) => parseFloat(dataObj.co2).toFixed(2)),
                sht31_temp: filteredRes.slice(-1).map((dataObj) => parseFloat(dataObj.sht31_temp).toFixed(2)),
                sht31_hum: filteredRes.slice(-1).map((dataObj) => parseFloat(dataObj.sht31_hum).toFixed(2)),
            });
            setIsDataAvailable(true);
        } else {
            setIsDataAvailable(false);
        }
    }, [selectedParameter, selectedParameterUnit, res]);

    return (
        <div className="graph-container">
            <div className="top-graph">
                <div className="graph-time">
                    {/* Time buttons go here */}
                </div>
                <div className="station-name">
                    <p>{"AQMS-Cisanti"}</p>
                </div>
            </div>
            <div className="bottom-graph">
                <div className="graph-parameter">
                    <div className="scrollable-button">
                        {AQMSCisanti.map((item, i) => (
                            <ParameterButton
                                key={i}
                                onClick={() =>
                                    handleDataChange(
                                        item.parameter,
                                        item.unit,
                                        item.high,
                                        item.low,
                                        item.name
                                    )
                                }
                                parameter={item.parameter}
                                unit={item.unit}
                                value={buttonData[item.parameter]}
                                name={item.name}
                                high={item.high}
                                low={item.low}
                            />
                        ))}
                    </div>
                </div>
                <ChartComponent data={data} />
            </div>
            <IndicatorGrid standard={standard} name={selectedParameterName} />
        </div>
    );
}

export default ChartAQMSCisanti;
