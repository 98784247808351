import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

function Addlog({ logs, onAddLog }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { logToEdit, isEdit } = location.state || {};

  const [log, setLog] = useState({
    id: null,
    station: "",
    report: "",
    startRepair: "",
    finishedRepair: "",
    status: "",
    date: "",
  });

  useEffect(() => {
    if (isEdit && logToEdit) {
      setLog(logToEdit);
    }
  }, [isEdit, logToEdit]);

  const stationOptions = ["GKU", "TULT", "DELI", "Tamansari","Cisanti"];
  const statusOptions = ["Up Coming", "On Going", "Finished"];

  const formatDateForMySQL = (date) => {
    const d = new Date(date); // Ensure we are working with a Date object

    // Helper function to pad single digit numbers with a leading zero
    const pad = (n) => (n < 10 ? "0" + n : n);

    // Format the date parts
    const year = d.getFullYear();
    const month = pad(d.getMonth() + 1); // Months are zero-indexed
    const day = pad(d.getDate());
    const hours = pad(d.getHours());
    const minutes = pad(d.getMinutes());
    const seconds = pad(d.getSeconds());

    // Construct and return the formatted date string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleChangeStation = (event) => {
    const selectedStation = event.target.value;
    setLog({ ...log, station: selectedStation });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLog({ ...log, [name]: value });
  };
  const Data = {
    station: log.station,
    report: log.report,
    startRepair: formatDateForMySQL(log.startRepair),
    finishedRepair: formatDateForMySQL(log.finishedRepair),
    status: log.status,
    date: formatDateForMySQL(log.date),
  };
  // console.log(Data);
  // console.log(log);

  const handleSubmit = async () => {
    const Data = {
      ...log,
      station: log.station,
      report: log.report,
      startRepair:log.startRepair,
      finishedRepair:log.finishedRepair,
      status: log.status,
      date:log.date,
    };
    // const Data = {
    //   station: "Station A",
    //   report: "Report A",
    //   startRepair: "2023-06-05 10:00:00",
    //   finishedRepair: "2023-06-05 12:00:00",
    //   status: "Completed",
    //   date: "2023-06-05 12:00:00",
    // };
    // console.log(Data);

    try {
      const response = await fetch(
        `https://biru-langit.com/api/logs${isEdit ? `/${log.id}` : ""}`,
        {
          method: isEdit ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Data),
        }
      );
      // console.log(Data);
      console.log("posted", response);

      if (!response.ok) {
        throw new Error("Failed to send log to the server");
      }

      const responseData = await response.json();
      onAddLog(responseData);
      navigate("/logmaintenance");
    } catch (error) {
      console.error("Error sending log to server:", error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container sx={{ pt: 0.5, pb: 5 }}>
        <Typography
          variant="h4"
          align="center"
          sx={{ fontWeight: "bold", fontSize: "24px", mb: 3 }}
        >
          {isEdit ? "Edit Log" : "Add Log"}
        </Typography>

        <TableContainer
          component={Paper}
          sx={{ mb: 2, width: "100%", maxWidth: 851, mx: "auto" }}
        >
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#D6E6F2",
                    width: "157px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{
                    padding: 0,
                    width: "694px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <TextField
                    type="datetime-local"
                    name="date"
                    value={log.date}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          sx={{ mb: 2, width: "100%", maxWidth: 851, mx: "auto" }}
        >
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#D6E6F2",
                    width: "157px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Station
                </TableCell>
                <TableCell
                  sx={{
                    padding: 0,
                    width: "694px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Select
                    value={log.station}
                    onChange={handleChangeStation}
                    label="Station"
                    sx={{ width: "100%" }}
                  >
                    {stationOptions.map((station) => (
                      <MenuItem key={station} value={station}>
                        {station}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          sx={{ mb: 2, width: "100%", maxWidth: 851, mx: "auto" }}
        >
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#D6E6F2",
                    width: "157px",
                    height: "160px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  Report
                </TableCell>
                <TableCell
                  sx={{
                    padding: 0,
                    width: "694px",
                    height: "160px",
                    textAlign: "center",
                    verticalAlign: "top",
                  }}
                >
                  <TextField
                    name="report"
                    value={log.report}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                    rows={6}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          sx={{ mb: 2, width: "100%", maxWidth: 851, mx: "auto" }}
        >
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#D6E6F2",
                    width: "157px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Start Repair
                </TableCell>
                <TableCell
                  sx={{
                    padding: 0,
                    width: "694px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <TextField
                    type="datetime-local"
                    name="startRepair"
                    value={log.startRepair}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          sx={{ mb: 2, width: "100%", maxWidth: 851, mx: "auto" }}
        >
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#D6E6F2",
                    width: "157px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Finished Repair
                </TableCell>
                <TableCell
                  sx={{
                    padding: 0,
                    width: "694px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <TextField
                    type="datetime-local"
                    name="finishedRepair"
                    value={log.finishedRepair}
                    onChange={handleInputChange}
                    fullWidth
                    inputProps={{
                      min: log.startRepair,
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer
          component={Paper}
          sx={{ mb: 2, width: "100%", maxWidth: 851, mx: "auto" }}
        >
          <Table sx={{ tableLayout: "fixed", width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    backgroundColor: "#D6E6F2",
                    width: "157px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    padding: 0,
                    width: "694px",
                    height: "39px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Select
                    name="status"
                    value={log.status}
                    onChange={handleInputChange}
                    label="Status"
                    sx={{ width: "100%" }}
                  >
                    {statusOptions.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Box mt={3} display="flex" justifyContent="center">
          <Button
            sx={{ backgroundColor: "#16425b", color: "white" }}
            onClick={handleSubmit}
          >
            {isEdit ? "Update" : "Input"}
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Addlog;
