import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import {
  Container,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Grid,
} from "@mui/material";
import "../../../Components/forecastTable/forecastTable.scss";

const ForecastTableCisanti = () => {
  const [dataCO2, setDataCO2] = useState([]);
  const [dataPM25, setDataPM25] = useState([]);

  const fetchDataCO2 = useCallback(async () => {
    try {
      const urlCO2 = `https://biru-langit.com/api/predict/read/Cisanti/CO2`;
      const response = await axios.get(urlCO2);
      if (Array.isArray(response.data)) {
        setDataCO2(response.data.slice(0, 6));
      } else {
        console.error("Invalid data format for CO2");
      }
    } catch (error) {
      console.error("Error fetching CO2 data:", error);
    }
  }, []);

  const fetchDataPM25 = useCallback(async () => {
    try {
      const urlPM25 = `https://biru-langit.com/api/predict/read/Cisanti/PM2.5`;
      const response = await axios.get(urlPM25);
      if (Array.isArray(response.data)) {
        setDataPM25(response.data.slice(0, 2));
      } else {
        console.error("Invalid data format for PM2.5");
      }
    } catch (error) {
      console.error("Error fetching PM2.5 data:", error);
    }
  }, []);

  useEffect(() => {
    fetchDataCO2();
    fetchDataPM25();
  }, [fetchDataCO2, fetchDataPM25]);

  return (
    <div style={{ backgroundColor: "#152b56" }}>
      <Container
        maxWidth="lg"
        sx={{ pt: 10, textAlign: "center", color: "white" }}
      >
        <h2 style={{ letterSpacing: "3px", fontSize: "32px" }}>Forecasting</h2>
        <p
          style={{
            paddingTop: "2px",
            paddingBottom: "30px",
            paddingLeft: "10px",
            paddingRight: "10px",
            letterSpacing: "2px",
          }}
        >
          Get the newest predicted data of air quality around you!
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} size="large">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      Date and time
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      CO2
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataCO2.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{row.created_at}</TableCell>
                      <TableCell align="center">
                        {row.co2 === null || isNaN(parseFloat(row.co2))
                          ? "NaN"
                          : `${parseFloat(row.co2).toFixed(1)} ppm`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300 }} size="large">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      Date and time
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "16px", fontWeight: "500" }}
                    >
                      PM2.5
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataPM25.map((row, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{row.created_at}</TableCell>
                      <TableCell align="center">
                        {row.pm25 === null || isNaN(parseFloat(row.pm25))
                          ? "NaN"
                          : `${parseFloat(row.pm25).toFixed(1)} µg/m3`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ForecastTableCisanti;
