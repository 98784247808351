export const fetchFlaggingData = async (
  callback,
  setNotifications,
  onNewNotifications,
  flagIcons
) => {
  const stations = ["GKU", "TULT", "Deli"];

  try {
      const updatedDataPromises = stations.map(async (station) => {
          const response = await fetch(`https://biru-langit.com/api/Flagging/${station}`);
          const { data } = await response.json();

          return data.map((item, index) => ({
              id: `${station}-${index + 1}`,
              no: index + 1,
              station,
              lastActive: item["Start Check"] || "N/A",
              lastOff: item["End Check"] || "N/A", // Gunakan End Check untuk waktu notifikasi
              flagNotif: item["Flag Notif"],
              flagIcon:
                  item["Flag Notif"] === 0 ? flagIcons.hijau :
                  item["Flag Notif"] === 1 ? flagIcons.kuning : flagIcons.merah,
              keterangan: item.Keterangan || "No description available",
              parameter: "All",
          }));
      });

      const allData = (await Promise.all(updatedDataPromises)).flat();
      const sortedData = allData.sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive));
      callback(sortedData);

      // Ambil tanggal dan jam saat ini
      const now = new Date();
      const currentDate = now.toISOString().split('T')[0]; // Ambil tanggal saat ini (YYYY-MM-DD)
      const currentHour = now.getHours(); // Ambil jam saat ini

      // Filter data berdasarkan jam dan tanggal yang sesuai dengan waktu sekarang
      const filteredDataForCurrentTime = sortedData.filter(row => {
          const endDate = new Date(row.lastOff);
          const endDateString = endDate.toISOString().split('T')[0]; // Ambil tanggal dari End Check
          const endHour = endDate.getHours(); // Ambil jam dari End Check

          return endDateString === currentDate && endHour === currentHour && row.flagNotif === 2;
      });

      // Batasi hanya 3 notifikasi per jam yang ditampilkan
      const notifications = filteredDataForCurrentTime.slice(0, 3).map(row => ({
          station: row.station,
          keterangan: row.keterangan,
          time: row.lastOff, // Gunakan End Check untuk waktu notifikasi
      }));

      setNotifications(notifications);

      if (onNewNotifications) {
          onNewNotifications(notifications);
      }
  } catch (error) {
      console.error("Error fetching data:", error);
  }
};
