const dataAQMSCisanti = () => {
    return [
      {
        parameter: "co2",
        unit: "ppm",
        value: "buttonData.co2",
        name: "CO2",
        high: 1000,
        low: 800,
      },
      {
        parameter: "pm25",
        unit: "μg/m3",
        value: "buttonData.pm25",
        name: "PM2.5",
        high: 50,
        low: 10,
      },
      {
        parameter: "T_out",
        unit: "°C",
        value: "buttonData.temperature",
        name: "Temperature",
        high: 1000,
        low: 800,
      },
      {
        parameter: "RH_out",
        unit: "%",
        value: "buttonData.humidity",
        name: "Humidity",
        high: 1000,
        low: 800,
      },
      {
        parameter: "T_in",
        unit: "°C",
        value: "buttonData.temp",
        name: "Temperature Shelter",
        high: 35,
        low: 25,
      },
      {
        parameter: "RH_in",
        unit: "%",
        value: "buttonData.humid",
        name: "Humidity Shelter",
        high: 100,
        low: 80,
      },
    ];
  };
  
  export { dataAQMSCisanti };

  const dataHeatmapCisanti = () => {
    return [
      {
        parameter: "co2",
        unit: "ppm",
        value: "co2",
        name: "CO2",
        high: 1000,
        low: 800,
      },
      {
        parameter: "pm25",
        unit: "μg/m3",
        value: "pm25",
        name: "PM2.5",
        high: 50,
        low: 10,
      },
      {
        parameter: "T_out",
        unit: "°C",
        value: "temperature",
        name: "Temperature",
        high: 1000,
        low: 800,
      },
      {
        parameter: "RH_out",
        unit: "%",
        value: "humidity",
        name: "Humidity",
        high: 1000,
        low: 800,
      },
      {
        parameter: "T_in",
        unit: "°C",
        value: "temp",
        name: "Temperature Shelter",
        high: 35,
        low: 25,
      },
      {
        parameter: "RH_in",
        unit: "%",
        value: "humid",
        name: "Humidity Shelter",
        high: 100,
        low: 80,
      },
    ];
  };
  
  export { dataHeatmapCisanti };
