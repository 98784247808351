import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Badge, IconButton } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

const NotificationPanel = ({ notifications }) => {
  const [open, setOpen] = useState(false);
  const panelRef = useRef(null); // Referensi ke notifikasi panel

  const toggleNotifications = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Cek apakah klik terjadi di luar notifikasi panel
      if (panelRef.current && !panelRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    // Tambahkan event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Bersihkan event listener saat komponen di-unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box>
      <IconButton onClick={toggleNotifications} color="inherit">
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {open && (
        <Box
          ref={panelRef} // Tambahkan referensi pada elemen notifikasi
          sx={{
            position: "absolute",
            top: "60px",
            right: "20px",
            width: "300px",
            backgroundColor: "white",
            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
            borderRadius: "8px",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "10px" }}>
            Notifications
          </Typography>
          {notifications.length > 0 ? (
            notifications.map((notif, index) => (
              <Box
                key={index}
                sx={{
                  marginBottom: "10px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  🚨 High Alert at {notif.station}
                </Typography>
                <Typography variant="body2">{notif.keterangan}</Typography>
                <Typography variant="caption" sx={{ color: "gray" }}>
                  Time: {notif.time}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No new notifications</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NotificationPanel;
