import React, { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../Components/Dashboard/ThemeDashboard";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined"; 
import HistoryIcon from "@mui/icons-material/History"; // Import ikon baru untuk Data History Flagging



const Item = ({ title, to, icon, selected, setSelected, handleItemClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        setSelected(title);
        if (handleItemClick) {
          handleItemClick(); // Call handleItemClick directly for Logout
        }
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} style={{ textDecoration: "none", color: "inherit" }} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("/dashboard"); 
  const navigate = useNavigate(); 

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/login"); 
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 15px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                ml={isCollapsed ? undefined : "0"}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "0px",
                  }}
                >
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <img
                      alt="logo"
                      width="150px"
                      height="50px"
                      src={`../../Assets/logobirulangit.png`}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Box>
                <IconButton
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  sx={{ marginBottom: "10px" }}
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? "5px" : "0%"}>
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Log Maintenance"
              to="/logmaintenance"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="SOP Document"
              to="/SOP"
              icon={<SummarizeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Data History"
              to="/datahistorical"
              icon={<AssignmentTurnedInIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Flagging System"
              to="/flagging-system"
              icon={<ReportGmailerrorredOutlinedIcon />} 
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Data History Flagging"
              to="/data-history-flagging" // Sesuaikan dengan rute yang Anda gunakan
              icon={<HistoryIcon />} // Ikon History
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Logout"
              to="#"
              icon={<LogoutIcon />}
              selected={selected}
              setSelected={setSelected}
              handleItemClick={handleLogout} 
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
