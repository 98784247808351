import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../ThemeDashboard";

const BarChart = ({ availabilityData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let myData = [];

  if (availabilityData && availabilityData.length > 0) {
    const firstItem = availabilityData[0];

    // Deteksi struktur API
    const isSW21Structure = "%T_in" in firstItem;

    // Pemetaan data untuk struktur SW21
    if (isSW21Structure) {
      myData = [
        {
          parameter: "PM2.5",
          persenavail: parseFloat(firstItem["%pm25"]).toFixed(1),
        },
        {
          parameter: "CO2",
          persenavail: parseFloat(firstItem["%co2"]).toFixed(1),
        },
        {
          parameter: "T",
          persenavail: parseFloat(firstItem["%T_out"]).toFixed(1),
        },
        {
          parameter: "RH",
          persenavail: parseFloat(firstItem["%RH_out"]).toFixed(1),
        },
        {
          parameter: "T Shelter",
          persenavail: parseFloat(firstItem["%T_in"]).toFixed(1),
        },
        {
          parameter: "RH Shelter",
          persenavail: parseFloat(firstItem["%RH_in"]).toFixed(1),
        },
        {
          parameter: "WS",
          persenavail: parseFloat(firstItem["%ws"]).toFixed(1),
        },
        {
          parameter: "WD",
          persenavail: parseFloat(firstItem["%wd"]).toFixed(1),
        },
        {
          parameter: "Pressure",
          persenavail: parseFloat(firstItem["%pressure"]).toFixed(1),
        },
        {
          parameter: "Solar",
          persenavail: parseFloat(firstItem["%solar"]).toFixed(1),
        },
      ];
    } else {
      // Pemetaan data untuk struktur GKU
      myData = [
        {
          parameter: "PM2.5",
          persenavail: parseFloat(firstItem["%pm25"]).toFixed(1),
        },
        {
          parameter: "CO2",
          persenavail: parseFloat(firstItem["%co2"]).toFixed(1),
        },
        {
          parameter: "T",
          persenavail: parseFloat(firstItem["%temperature"]).toFixed(1),
        },
        {
          parameter: "RH",
          persenavail: parseFloat(firstItem["%humidity"]).toFixed(1),
        },
        {
          parameter: "T Shelter",
          persenavail: parseFloat(firstItem["%sht31_temp"]).toFixed(1),
        },
        {
          parameter: "RH Shelter",
          persenavail: parseFloat(firstItem["%sht31_hum"]).toFixed(1),
        },
        {
          parameter: "WS",
          persenavail: parseFloat(firstItem["%ws"]).toFixed(1),
        },
        {
          parameter: "WD",
          persenavail: parseFloat(firstItem["%wd"]).toFixed(1),
        },
      ];
    }
  } else {
    console.error("availabilityData is null or undefined.");
  }

  return (
    <ResponsiveBar
      data={myData}
      theme={{
        axis: {
          domain: { line: { stroke: colors.grey[100] } },
          legend: { text: { fill: colors.grey[100] } },
          ticks: {
            line: { stroke: colors.grey[100], strokeWidth: 1 },
            text: { fill: colors.grey[100] },
          },
        },
        legends: { text: { fill: colors.grey[100] } },
      }}
      keys={["persenavail"]}
      indexBy="parameter"
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear", min: 0, max: 100 }}
      indexScale={{ type: "band", round: true }}
      colors={(d) => {
        if (d.value > 75) return "#16425B"; // Biru
        if (d.value > 50) return "orange"; // Oranye
        if (d.value > 25) return "red"; // Merah
        return "black"; // Hitam
      }}
      borderColor={{ from: "color", modifiers: [["darker", "1.6"]] }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -40,
        format: (value) => `${value}%`,
      }}
      enableLabel={true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={colors.birulangit[500]}
      role="application"
      barAriaLabel={(e) =>
        `${e.id}: ${e.formattedValue} in parameter: ${e.indexValue}`
      }
    />
  );
};

export default BarChart;
